export enum SiteIdEnums {
  DEFAULT = '',
  WTRASIE = 'WTRASIE',
  TELBOOK = 'TELBOOK',
  POLSKIDEV = 'POLSKIDEV',
  AUA = 'AUA',
  ADMIN = 'ADMIN',
  UUJ = 'UUJ',
  AUTOMOP = 'AUTOMOP',
  ABCTIR = 'ABCTIR',
}
