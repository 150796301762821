import {SiteConfig} from "../../../../common/types";
import type {NextPageContext} from 'next';
import type {DocumentContext} from "next/document";
import {getHeaderCookieConsentSettings} from "./getHeaderCookieConsentSettings";
import {getHeader} from "./getHeader";

export function getHeaderClient(ctx: NextPageContext | DocumentContext): SiteConfig['client'] {
    const isMobile = getHeader(ctx, 'uxu-client-mobile') === 'true';

    const osInfo = {
        isWindows: getHeader(ctx, 'uxu-client-windows') === 'true',
        isLinux: getHeader(ctx, 'uxu-client-linux') === 'true',
        isMacOS: getHeader(ctx, 'uxu-client-mac-os') === 'true',
    };

    return {
        locale: getHeader(ctx, 'uxu-client-locale'),
        referenceIdPartner: getHeader(ctx, 'uxu-reference-id-partner'),
        cookieConsentSettings: getHeaderCookieConsentSettings(ctx),
        platform: {
            isMobile,
            isDesktop: !isMobile,
        },
        osInfo,
    };
}
