// store/authSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { type LogtoContext, type UserInfoResponse } from '@logto/next';
import axios from 'axios';

const initialState: LogtoContext & { isLoadingData: boolean } = {
    isAuthenticated: false,
    claims: undefined,
    accessToken: undefined,
    userInfo: undefined,
    scopes: undefined,
    organizationTokens: undefined,
    isLoadingData: false, // Tracks whether the user data is being loaded
};

export const loadUserData = createAsyncThunk(
    'auth/loadUserData',
    async (): Promise<LogtoContext> => {
        const res = await axios.get<LogtoContext>('/api/logto/user');
        if (res.status === 200) {
            return res.data;
        }
        throw new Error('Failed to load user data');
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthenticated(state, action: PayloadAction<Partial<LogtoContext>>) {
            state.isAuthenticated = true;
            Object.assign(state, action.payload);
        },
        clearSession(state) {
            Object.assign(state, initialState);
        },
        setAccessToken(state, action: PayloadAction<string>) {
            state.accessToken = action.payload;
        },
        setUserInfo(state, action: PayloadAction<UserInfoResponse>) {
            state.userInfo = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadUserData.pending, (state) => {
            state.isLoadingData = true; // Set loading to true when the request starts
        });
        builder.addCase(loadUserData.fulfilled, (state, action) => {
            Object.assign(state, action.payload);
            state.isLoadingData = false; // Set loading to false after successful request
        });
        builder.addCase(loadUserData.rejected, (state) => {
            Object.assign(state, initialState);
            state.isLoadingData = false; // Set loading to false if the request fails
        });
    },
});

export const { setAuthenticated, clearSession, setAccessToken, setUserInfo } = authSlice.actions;
export default authSlice.reducer;