import { createContext, type PropsWithChildren, type ReactElement } from 'react';
import { SiteIdEnums } from '@uxu/utils/common/enums';
import type { SiteConfig } from '@uxu/utils/common/types';

const initialSiteConfig: SiteConfig = {
    admin: {},
    marketingToolsConfig: {},
    port: 3000,
    projectName: '',
    site: {
        id: SiteIdEnums.DEFAULT,
        locale: 'pl',
        domain: '',
        slug: "/",
        brand: "uxu",
        shortBrand: "uxu",
        defaultCover: '/defaultCover.png',
        canonicalUrl: '',
        title: '',
        shortname: '',
        description: '',
        authEnabled: false,
        switchTheme: true,
        images: [],
    },
    social: {
        facebook: {
            pageId: ''
        }
    },
    client: {
        locale: 'pl',
        platform: {
            isDesktop: false,
            isMobile: false,
        },
        osInfo: {
            isWindows: false,
            isLinux: false,
            isMacOS: false,
        },
        cookieConsentSettings: {
            ads: false,
            analytics: false
        }
    },
    apps: {
        langs: [],
        data: []
    },
};

export const SiteConfigContext = createContext<SiteConfig>(initialSiteConfig);

type SiteConfigProviderProps = PropsWithChildren<{ siteConfig: SiteConfig }>;

export function SiteConfigProvider({ children, siteConfig }: SiteConfigProviderProps): ReactElement {
    return (
        <SiteConfigContext.Provider value={siteConfig}>
            {children}
        </SiteConfigContext.Provider>
    );
}