import type {SiteConfig, AdSlotsMap} from "@uxu/utils/common/types";
import {appsData} from "@uxu/utils/common/consts";
import {SiteIdEnums,} from "@uxu/utils/common/enums";

const adSlotsMap: AdSlotsMap = new Map([])

export const locale = 'pl';

export const siteConfig: SiteConfig = {
    marketingToolsConfig: {
        googleTagManagerId: 'GTM-MC3DNS7',
        googleAdManagerId: '23023978625',
        adSlotsMap,
    },
    port: 3000,
    projectName: 'admin',
    site: {
        id: SiteIdEnums.ADMIN,
        locale,
        domain: 'admin.uxu.pl',
        slug: '/',
        brand: "uxu",
        shortBrand: "uxu",
        defaultCover: 'https://admin.uxu.pl/defaultCover.png',
        canonicalUrl: process.env.NEXT_PUBLIC_SITE_URL || `https://admin.uxu.pl`,
        images: [{url: 'https://admin.uxu.pl/ogDefaultCover.png'}],
        title: 'UXU - panel administracyjny',
        shortname: 'wt',
        description: 'panel administracyjny, ułatwiający zarządzanie stronami internetowymi i aplikacjami',
        authEnabled: true,
        switchTheme: true,
    },
    social: {
        facebook: {
            pageId: '61565800479632'
        },
        github: {
            accountName: 'pawel-niedzwiecki',
        },
        twitter: {
            accountName: 'p_niedzwiecki',
        },
        linkedin: {
            accountName: 'pawel-niedzwiecki',
        }
    },
    client: {
        locale,
        platform: {
            isDesktop: false,
            isMobile: false,
        },
        osInfo: {
            isWindows: false,
            isLinux: false,
            isMacOS: false,
        },
        cookieConsentSettings: {
            ads: false,
            analytics: false
        }
    },
    admin: {
        company: {
            name: "Euro-Trans Parys Paweł",
            street: "Tomasza Nocznickiego 9",
            postCode: "07-300",
            city: "Ostrów Mazowiecka",
            tax: {
                pl: {
                    nip: '7591684079',
                    regon: '364079927',
                }
            },
            contact: {
                pl: {
                    email: [{type: "main", email: "hello@uxu.pl"}],
                    phone: [{type: "mobile", number: "888 333 885"}]
                }
            }
        }
    },
    apps: {
        data: appsData,
        langs: [{code: "pl", name: "polski"}, {code: "en", name: "angielski"}]
    }
};
