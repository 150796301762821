import LogtoClient from '@logto/next';

export const logtoClient = new LogtoClient({
  endpoint: process.env.NEXT_PUBLIC_LOGTO_ENDPOINT!,
  appId: process.env.NEXT_PUBLIC_LOGTO_APP_ID!,
  appSecret: process.env.NEXT_SECRET_LOGTO_APP_SECRET!,
  baseUrl: process.env.NEXT_PUBLIC_LOGTO_BASE_URL!,
  cookieSecret: process.env.NEXT_PUBLIC_LOGTO_COOKIE_SECRET!,
  cookieSecure: process.env.NEXT_PUBLIC_NODE_ENV === 'production',
  scopes: process.env.NEXT_PUBLIC_LOGTO_SCOPES?.split(' ') ?? ['openid', 'offline_access', 'profile', 'organizations', 'roles'],
});