

export function generateImageLinks(url: string): Record<"o" | "xs" | "s" | "m" | "l" | "xl", string> | null {
    // Extract the file name and format from the URL
    const match = url.match(/\/([^\/?#]+)\.([a-zA-Z0-9]+)(?:\?.*|#.*|$)/);
    if (!match) {
        return null; // Invalid URL, return null
    }

    const [_, fileName, format] = match;

    // Supported formats
    const supportedFormats = ["jpg", "jpeg", "png"];
    if (!supportedFormats.includes(format.toLowerCase())) {
        return null; // Unsupported format, return null
    }

    // Keys for resizing and generating URLs
    const keys = ["o", "xs", "s", "m", "l", "xl"];
    const links: Record<string, string> = {};

    keys.forEach((key) => {
        if (key === "o") {
            // Original key keeps the input URL
            links[key] = url;
        } else {
            // Generate new links with prefix and change format to .webp
            links[key] = url.replace(`${fileName}.${format}`, `${key}-${fileName}.webp`);
        }
    });

    return links;
}