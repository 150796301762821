import type { Apps } from '../types';

export const appsData: Apps['data'] = [
  { client_id: '2n1551ee931wtz1vua7wo', domain: 'wtrasie.pl' },
  { client_id: '0jxy6vs6m73ngdbza2yab', domain: 'telbook.info' },
  { client_id: 'g7mt1xms4o9srmdnpm9qh', domain: 'aua.pl' },
  { client_id: 'k01jb0tzq2j6rbvsajp54', domain: 'polski.dev' },
  { client_id: 'f3eqpz9z6jubxshngvaln', domain: 'uuj.pl' },
  { client_id: '9olxdtswo610qbcz9a7by', domain: 'abctir.pl' },
  { client_id: '2arp96u8639rbtuktqvok', domain: 'automop.pl' },
];
