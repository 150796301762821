import type { BranchProps } from "@uxu/design-system/components/molecules/tree";
import { HeaderMenuConfigTypes } from '@uxu/design-system/components/layout/types';

export const headerMenuConfig: HeaderMenuConfigTypes = {
  links: [
    { href: "/dashboard", title: "Home" },
    { href: "/contact", title: "Kontakt" }
  ]
}

export const siteBarMenuConfig: Array<BranchProps> = [
  {
    title: "Wiadomości", href: "/",
  },
  {
    title: "Usługi", href: "/s",
  },
]
